const regular = {
  // 邮箱
  regEmail: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
  // 身份证
  regCard18: /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
  // 强密码(必须包含大小写字母和数字的组合，不能使用特殊字符，长度在8-10之间)
  regHardPwd: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,10}$/,
  // 收尾空白字符
  regBlankChars: /(^\s*)|(\s*$)/,
  // ------------------------------------当前项目
  // 手机号(原型规则为任意字符11位)
  regPhone: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
  // QQ
  regQQ: /^[0-9]{5,12}$/,
  // 服务商名称
  regProviderName: /^[\u4e00-\u9fa5a-zA-Z-_]{2,15}$/,
  // url
  regUrl: /(http|https):\/\/([\w.]+\/?)\S*/,
  // 服务商介绍
  regIntroduction: /^[\s\S]{1,70}$/,
  // 公司名称
  regCompanyName: /^[\u4e00-\u9fa5]{1,1000}$/,
  // 信用代码
  regCreditCode: /^[0-9a-zA-Z_-]{1,1000}$/,
  // 中英文
  regChineseEnglish: /^[\u4e00-\u9fa5a-zA-Z-_]{1,1000}$/,
  regChineseEnglish16: /^[\u4e00-\u9fa5a-zA-Z-_]{1,16}$/,
  // 验证码
  regCaptcha: /^[0-9]{6}$/,
  // 粉丝数
  regFansNumber: /^[0-9]{1,14}$/,
  // 中文
  regChinese: /^[\u4E00-\u9FA5]+$/,
  // 密码
  regPassword: /^[\x21-\x7e]{6,14}$/
}
export default regular
