const Statistical = {
  // 首页部分埋点代码
  index: {
    indexShow () {
      window._hmt.push(['_trackEvent', 'index_show', 'show', '页面浏览'])
    },
    flowPlat () {
      window._hmt.push(['_trackEvent', 'index_flow_plat', 'click', '点击导航流量平台'])
    },
    operate () {
      window._hmt.push(['_trackEvent', 'index_operational_services', 'click', '点击业务导航爆款运营服务'])
    },
    ranking () {
      window._hmt.push(['_trackEvent', 'index_ranking', 'click', '点击业务导航查排名'])
    },
    accountNumber () {
      window._hmt.push(['_trackEvent', 'index_accountNumber', 'click', '点击业务导航查黑号'])
    },
    conversion () {
      window._hmt.push('_trackEvent', 'index_conversion', 'click', '点击业务导航指数还原')
    },
    banner () {
      window._hmt.push(['_trackEvent', 'index_banner', 'click', '点击banner'])
    },
    serviceSignUp () {
      window._hmt.push(['_trackEvent', 'index_service_signUp', 'click', '点击服务包报名'])
    },
    diagnosisSignUp () {
      window._hmt.push(['_trackEvent', 'index_diagnosis_signUp', 'click', '点击立即报名（店铺诊断页面）'])
    },
    login () {
      window._hmt.push(['_trackEvent', 'index_login', 'click', '点击登录'])
    },
    register () {
      window._hmt.push(['_trackEvent', 'index_register', 'click', '点击免费注册'])
    },
    serviceMarket () {
      window._hmt.push(['_trackEvent', 'index_service_market', 'click', '点击服务市场首页'])
    },
    businessbCenter () {
      window._hmt.push(['_trackEvent', 'index_businessbCenter', 'click', '点击商家中心'])
    },
    serviceprovider () {
      window._hmt.push(['_trackEvent', 'index_serviceprovider', 'click', '点击服务商入驻/服务商中心'])
    },
    savebusiness () {
      window._hmt.push(['_trackEvent', 'index_savebusiness', 'click', '完善商家资料'])
    },
    serviceClassify: {
      operate () {
        window._hmt.push(['_trackEvent', 'index_serviceClassify_operate', 'click', '点击分类服务爆款运营'])
      },
      agencyOperation () {
        window._hmt.push(['_trackEvent', 'index_serviceClassify_agencyOperation', 'click', '点击分类服务店铺代运营'])
      },
      shopDiagnosis () {
        window._hmt.push(['_trackEvent', 'index_serviceClassify_shopDiagnosis', 'click', '点击分类服务店铺诊断'])
      },
      ranking () {
        window._hmt.push(['_trackEvent', 'index_serviceClassify_ranking', 'click', '点击分类服务查排名'])
      },
      wangQuery () {
        window._hmt.push(['_trackEvent', 'index_serviceClassify_wangQuery', 'click', '点击分类服务查黑号'])
      },
      firstScreent () {
        window._hmt.push(['_trackEvent', 'index_serviceClassify_firstScreent', 'click', '点击分类服务卡首屏'])
      },
      conversion () {
        window._hmt.push(['_trackEvent', 'index_serviceClassify_conversion', 'click', '点击分类服务指数转化'])
      },
      shopDecoration () {
        window._hmt.push(['_trackEvent', 'index_serviceClassify_shopDecoration', 'click', '点击分类服务店铺装修'])
      },
      designService () {
        window._hmt.push(['_trackEvent', 'index_serviceClassify_designService', 'click', '点击分类服务设计服务'])
      },
      ztcAdvertising () {
        window._hmt.push(['_trackEvent', 'index_serviceClassify_ztcAdvertising', 'click', '点击分类服务直通车'])
      },
      diamondRecommend () {
        window._hmt.push(['_trackEvent', 'index_serviceClassify_diamondRecommend', 'click', '点击分类服务钻展'])
      },
      superRecommend () {
        window._hmt.push(['_trackEvent', 'index_serviceClassify_superRecommend', 'click', '点击分类服务超推'])
      }
    },
    tool: {
      ranking () {
        window._hmt.push(['_trackEvent', 'index_tool_ranking', 'click', '点击电商工具查排名'])
      },
      wangQuery () {
        window._hmt.push(['_trackEvent', 'index_tool_wangQuery', 'click', '点击电商工具查黑号'])
      },
      orderDetection () {
        window._hmt.push(['_trackEvent', 'index_tool_orderDetection', 'click', '点击电商工具淘宝订单查询'])
      },
      wangSign () {
        window._hmt.push(['_trackEvent', 'index_tool_wangSign', 'click', '点击电商工具旺旺打标'])
      },
      top20w () {
        window._hmt.push(['_trackEvent', 'index_tool_top20w', 'click', '点击电商工具TOP20W词库'])
      },
      diction () {
        window._hmt.push(['_trackEvent', 'index_tool_diction', 'click', '点击电商工具下拉框选词'])
      },
      picture () {
        window._hmt.push(['_trackEvent', 'index_tool_picture', 'click', '点击电商工具主图/视频...下载'])
      },
      conversion () {
        window._hmt.push(['_trackEvent', 'index_tool_conversion', 'click', '点击电商工具指数还原'])
      },
      weightPromote () {
        window._hmt.push(['_trackEvent', 'index_tool_weightPromote', 'click', '点击电商工具权重提升计划'])
      },
      terminal () {
        window._hmt.push(['_trackEvent', 'index_tool_terminal', 'click', '点击电商工具PC/无线...下载'])
      },
      category () {
        window._hmt.push(['_trackEvent', 'index_tool_category', 'click', '点击电商工具类目查询'])
      },
      firstScreent () {
        window._hmt.push(['_trackEvent', 'index_tool_firstScreent', 'click', '点击电商工具关键词卡首屏'])
      }
    },
    operationServices: {
      operate () {
        window._hmt.push(['_trackEvent', 'index_operationServices_operate', 'click', '点击运营服务爆款运营服务'])
      },
      shopDiagnosis () {
        window._hmt.push(['_trackEvent', 'index_operationServices_shopDiagnosis', 'click', '点击运营服务店铺诊断服务'])
      },
      ztcAdvertising () {
        window._hmt.push(['_trackEvent', 'index_operationServices_ztcAdvertising', 'click', '点击运营服务直通车推广服务'])
      },
      shopDecoration () {
        window._hmt.push(['_trackEvent', 'index_operationServices_shopDecoration', 'click', '点击运营服务店铺装修服务'])
      },
      agencyOperation () {
        window._hmt.push(['_trackEvent', 'index_operationServices_agencyOperation', 'click', '点击运营服务店铺代运营'])
      }
    }
  },
  // 登录页面
  login: {
    register () {
      window._hmt.push(['_trackEvent', 'login_register', 'click', '点击免费注册'])
    },
    login () {
      window._hmt.push(['_trackEvent', 'login_show', 'show', '页面浏览'])
    },
    tologin () {
      window._hmt.push(['_trackEvent', 'login_tologin', 'click', '点击登录'])
    }
  },
  // 注册页面
  register: {
    register () {
      window._hmt.push(['_trackEvent', 'register_show', 'show', '页面浏览'])
    },
    openUserAgreement () {
      window._hmt.push(['_trackEvent', 'register_openUserAgreement', 'click', '点击《用户服务协议》'])
    },
    quicklogon () {
      window._hmt.push(['_trackEvent', 'register_quicklogon', 'click', '点击快速登录'])
    },
    indexClick () {
      window._hmt.push(['_trackEvent', 'register_indexClick', 'click', '点击抖脉首页'])
    },
    toregister () {
      window._hmt.push(['_trackEvent', 'register_toregister', 'click', '点击注册'])
    },
    registerSuccess () {
      window._hmt.push(['_trackEvent', 'register_success', 'show', '页面浏览'])
    }
  },
  // 注册/登录弹框
  signModal: {
    verificationCode () {
      window._hmt.push(['_trackEvent', 'index_verification_code', 'click', '点击验证码'])
    },
    sign () {
      window._hmt.push(['_trackEvent', 'index_sign/register', 'click', '点击登录/注册按钮'])
    }
  },
  // 完善商家资料
  improveinformation: {
    improveinformationSuccess () {
      window._hmt.push(['_trackEvent', 'improveinformation_success', 'click', '点击保存'])
    },
    improveinformationError () {
      window._hmt.push(['_trackEvent', 'improveinformation_error', 'click', '点击关闭'])
    }
  },
  // 爆款运营服务-选购页
  operationalServices: {
    index () {
      window._hmt.push(['_trackEvent', 'operationalServices_show', 'show', '页面浏览'])
    },
    topay () {
      window._hmt.push(['_trackEvent', 'operationalServices_topay', 'click', '点击立即支付'])
    },
    closepay () {
      window._hmt.push(['_trackEvent', 'operationalServices_closepay', 'click', '立即支付弹框-点击关闭'])
    },
    paySure () {
      window._hmt.push(['_trackEvent', 'operationalServices_paySure', 'click', '立即支付弹框-点击确定支付'])
    },
    isClosePay () {
      window._hmt.push(['_trackEvent', 'operationalServices_isClosePay', 'click', '立即支付弹框-点击已关闭支付'])
    }
  },
  // 流量平台-首页
  flowIndex: {
    index () {
      window._hmt.push(['_trackEvent', 'flowIndex_show', 'show', '页面浏览'])
    },
    taskCenter () {
      window._hmt.push(['_trackEvent', 'flowIndex_taskCenter', 'click', '点击任务中心'])
    },
    personalCenter () {
      window._hmt.push(['_trackEvent', 'flowIndex_personalCenter', 'click', '点击个人中心'])
    },
    signOut () {
      window._hmt.push(['_trackEvent', 'flowIndex_signOut', 'click', '点击退出登录'])
    }
  },
  // 流量平台-任务中心
  taskCenter: {
    index () {
      window._hmt.push(['_trackEvent', 'taskCenter_show', 'show', '页面浏览'])
    },
    taskTemplate () {
      window._hmt.push(['_trackEvent', 'taskCenter_taskTemplate', 'click', '点击任务模板'])
    },
    taskQuery () {
      window._hmt.push(['_trackEvent', 'taskCenter_taskQuery', 'click', '点击任务查询'])
    },
    flowRecharge () {
      window._hmt.push(['_trackEvent', 'taskCenter_flowRecharge', 'click', '点击流量充值'])
    }
  },
  // 流量平台-淘宝、京东、拼多多任务
  platformTask: {
    taobao: {
      index () {
        window._hmt.push(['_trackEvent', 'platformTask_taobao_show', 'show', '页面浏览'])
      },
      type () {
        window._hmt.push(['_trackEvent', 'platformTask_taobao_type', 'click', '点击任务类型'])
      }
    },
    jingdong: {
      index () {
        window._hmt.push(['_trackEvent', 'platformTask_jingdong_show', 'show', '页面浏览'])
      },
      type () {
        window._hmt.push(['_trackEvent', 'platformTask_jingdong_type', 'click', '点击任务类型'])
      }
    },
    pinduoduo: {
      index () {
        window._hmt.push(['_trackEvent', 'platformTask_pinduoduo_show', 'show', '页面浏览'])
      },
      type () {
        window._hmt.push(['_trackEvent', 'platformTask_pinduoduo_type', 'click', '点击任务类型'])
      }
    },
    taskRelease () {
      window._hmt.push(['_trackEvent', 'platformTask_taskRelease', 'click', '点击任务发布'])
    },
    taskReset () {
      window._hmt.push(['_trackEvent', 'platformTask_taskReset', 'click', '点击重置按钮'])
    },
    taskSave () {
      window._hmt.push(['_trackEvent', 'platformTask_taskSave', 'click', '点击保存按钮'])
    }
  },
  // 流量平台-任务模板
  taskTemplate: {
    index () {
      window._hmt.push(['_trackEvent', 'taskTemplate_show', 'show', '页面浏览'])
    },
    query () {
      window._hmt.push(['_trackEvent', 'taskTemplate_query', 'click', '点击查询按钮'])
    }
  },
  // 流量平台-任务查询
  taskQuery: {
    index () {
      window._hmt.push(['_trackEvent', 'taskQuery_show', 'show', '页面浏览'])
    },
    query () {
      window._hmt.push(['_trackEvent', 'taskQuery_query', 'click', '点击查询按钮'])
    }
  },
  // 流量平台-流量充值
  flowRecharge: {
    index () {
      window._hmt.push(['_trackEvent', 'flowRecharge_show', 'show', '页面浏览'])
    },
    toPay () {
      window._hmt.push(['_trackEvent', 'flowRecharge_toPay', 'click', '点击立即抢购按钮'])
    }
  },
  // 流量平台-个人中心
  flowCenter: {
    index () {
      window._hmt.push(['_trackEvent', 'flowCenter_show', 'show', '页面浏览'])
    }
  },
  // 店铺诊断
  shopDiagnosis: {
    index () {
      window._hmt.push(['_trackEvent', 'shopDiagnosis_show', 'show', '页面浏览'])
    },
    addShopDiagnoseDemand () {
      window._hmt.push(['_trackEvent', 'shopDiagnosis_addShopDiagnoseDemand', 'click', '点击提交信息'])
    }
  },
  // 服务市场
  marker: {
    index () {
      window._hmt.push(['_trackEvent', 'marker_show', 'show', '页面浏览'])
    },
    changeType () {
      window._hmt.push(['_trackEvent', 'marker_changeType', 'click', '点击分类'])
    },
    changeBusiness () {
      window._hmt.push(['_trackEvent', 'marker_changeBusiness', 'click', '点击商家类型'])
    },
    onDetailClick () {
      window._hmt.push(['_trackEvent', 'marker_onDetailClick', 'click', '点击立即报名'])
    }
  },
  // 服务包详情
  serviceDetails: {
    index () {
      window._hmt.push(['_trackEvent', 'serviceDetails_show', 'show', '页面浏览'])
    },
    onSignUpClick () {
      window._hmt.push(['_trackEvent', 'serviceDetails_onSignUpClick', 'click', '点击立即报名'])
    },
    cost () {
      window._hmt.push(['_trackEvent', 'serviceDetails_cost', 'click', '点击费用预估'])
    },
    onGetResultClick () {
      window._hmt.push(['_trackEvent', 'serviceDetails_onGetResultClick', 'click', '点击开始计算'])
    },
    diagnosisSignUp () {
      window._hmt.push(['_trackEvent', 'serviceDetails_diagnosisSignUp', 'click', '点击立即报名'])
    }
  },
  // 激活码提示框
  activationCode: {
    confirm () {
      window._hmt.push(['_trackEvent', 'activationCode_confirm', 'click', '点击激活按钮'])
    },
    close () {
      window._hmt.push(['_trackEvent', 'activationCode_close', 'click', '点击关闭图标'])
    }
  },
  // 类目查询
  category: {
    index () {
      window._hmt.push(['_trackEvent', 'category_show', 'show', '页面浏览'])
    },
    query () {
      window._hmt.push(['_trackEvent', 'category_query', 'click', '点击查询按钮'])
    }
  },
  // 旺旺打标
  wangSign: {
    index () {
      window._hmt.push(['_trackEvent', 'wangSign_show', 'show', '页面浏览'])
    },
    query () {
      window._hmt.push(['_trackEvent', 'wangSign_query', 'click', '点击立即打标按钮'])
    }
  },
  // 宝贝查排名
  ranking: {
    index () {
      window._hmt.push(['_trackEvent', 'ranking_show', 'show', '页面浏览'])
    },
    query () {
      window._hmt.push(['_trackEvent', 'ranking_query', 'click', '点击提交查询按钮'])
    }
  },
  // 关键词卡首屏
  firstScreent: {
    index () {
      window._hmt.push(['_trackEvent', 'firstScreent_show', 'show', '页面浏览'])
    },
    query () {
      window._hmt.push(['_trackEvent', 'firstScreent_query', 'click', '点击生成按钮'])
    }
  },
  // 淘客订单检测
  orderDetection: {
    index () {
      window._hmt.push(['_trackEvent', 'orderDetection_show', 'show', '页面浏览'])
    },
    query () {
      window._hmt.push(['_trackEvent', 'orderDetection_query', 'click', '点击查询按钮'])
    }
  },
  // TOP20W词下载
  top20w: {
    index () {
      window._hmt.push(['_trackEvent', 'top20w_show', 'show', '页面浏览'])
    },
    pcTerminal () {
      window._hmt.push(['_trackEvent', 'top20w_pcTerminal', 'click', '点击PC端TOP20W词表-立即下载'])
    },
    wirelessTerminal () {
      window._hmt.push(['_trackEvent', 'top20w_wirelessTerminal', 'click', '点击无线端TOP20W词表-立即下载'])
    },
    potentialWords () {
      window._hmt.push(['_trackEvent', 'top20w_potentialWords', 'click', '点击潜力词表-立即下载'])
    }
  },
  // 下拉框选词
  diction: {
    index () {
      window._hmt.push(['_trackEvent', 'diction_show', 'show', '页面浏览'])
    },
    query () {
      window._hmt.push(['_trackEvent', 'diction_query', 'click', '点击查询按钮'])
    }
  },
  // PC/无线端详情页下载
  terminal: {
    index () {
      window._hmt.push(['_trackEvent', 'terminal_show', 'show', '页面浏览'])
    },
    query () {
      window._hmt.push(['_trackEvent', 'terminal_query', 'click', '点击查询按钮'])
    },
    allDownload () {
      window._hmt.push(['_trackEvent', 'terminal_allDownload', 'click', '点击下载全部按钮'])
    },
    download () {
      window._hmt.push(['_trackEvent', 'terminal_download', 'click', '点击下载'])
    },
    close () {
      window._hmt.push(['_trackEvent', 'terminal_close', 'click', '点击关闭按钮'])
    }
  },
  // 主图/视频/SKU图下载
  picture: {
    index () {
      window._hmt.push(['_trackEvent', 'picture_show', 'show', '页面浏览'])
    },
    download () {
      window._hmt.push(['_trackEvent', 'picture_download', 'click', '点击下载按钮'])
    }
  },
  // 权重提升计划
  weightPromote: {
    index () {
      window._hmt.push(['_trackEvent', 'weightPromote_show', 'show', '页面浏览'])
    },
    onGeneratePlan () {
      window._hmt.push(['_trackEvent', 'weightPromote_onGeneratePlan', 'click', '点击一键生成计划'])
    }
  },
  // 指数还原
  conversion: {
    index () {
      window._hmt.push(['_trackEvent', 'conversion_show', 'show', '页面浏览'])
    },
    textareaData () {
      window._hmt.push(['_trackEvent', 'conversion_textareaData', 'click', '点击数据项'])
    },
    getData () {
      window._hmt.push(['_trackEvent', 'conversion_getData', 'click', '点击立即换算'])
    },
    empty () {
      window._hmt.push(['_trackEvent', 'conversion_empty', 'click', '点击清空数据'])
    },
    copy () {
      window._hmt.push(['_trackEvent', 'conversion_copy', 'click', '点击复制结果'])
    }
  },
  // 商家中心
  businessCenter: {
    index () {
      window._hmt.push(['_trackEvent', 'businessCenter_show', 'show', '页面浏览'])
    },
    servicesheetmanagement () {
      window._hmt.push(['_trackEvent', 'businessCenter_servicesheetmanagement', 'click', '点击服务单管理'])
    },
    ordermanagement () {
      window._hmt.push(['_trackEvent', 'businessCenter_ordermanagement', 'click', '点击订单管理'])
    },
    cancelOrder () {
      window._hmt.push(['_trackEvent', 'businessCenter_cancelOrder', 'click', '点击取消（服务单管理）'])
    },
    onUpdateMerchantOrderStatus () {
      window._hmt.push(['_trackEvent', 'businessCenter_onUpdateMerchantOrderStatus', 'click', '点击验收（服务单管理）'])
    },
    addAwaitPayOrder () {
      window._hmt.push(['_trackEvent', 'businessCenter_addAwaitPayOrder', 'click', '点击支付（订单管理）'])
    },
    closeOrder () {
      window._hmt.push(['_trackEvent', 'businessCenter_closeOrder', 'click', '点击关闭订单（订单管理）'])
    },
    deleteOrder () {
      window._hmt.push(['_trackEvent', 'businessCenter_deleteOrder', 'click', '点击删除订单（订单管理）'])
    },
    merchantInfoClick () {
      window._hmt.push(['_trackEvent', 'businessCenter_merchantInfo', 'click', '点击账号管理'])
    }
  },
  // 跳转来源
  href: {
    // 导航
    navigation (name, title) {
      console.log(name, title, 519)
      window._hmt.push(['_trackEvent', 'navigation_' + name, 'href', title + '跳转来源导航'])
    },
    // 分类服务
    classify: {
      operate () {
        window._hmt.push(['_trackEvent', 'classify_operate', 'href', '爆款运营跳转来源分类服务名称'])
      },
      shopDiagnosis () {
        window._hmt.push(['_trackEvent', 'classify_shopDiagnosis', 'href', '店铺诊断跳转来源分类服务名称'])
      },
      ranking () {
        window._hmt.push(['_trackEvent', 'classify_ranking', 'href', '查排名跳转来源分类服务名称'])
      },
      firstScreent () {
        window._hmt.push(['_trackEvent', 'classify_firstScreent', 'href', '卡首屏跳转来源分类服务名称'])
      }
    },
    // 电商工具
    tool: {
      ranking () {
        window._hmt.push(['_trackEvent', 'tool_ranking', 'href', '查排名跳转来源电商工具'])
      },
      orderDetection () {
        window._hmt.push(['_trackEvent', 'tool_orderDetection', 'href', '淘宝订单查询跳转来源电商工具'])
      },
      wangSign () {
        window._hmt.push(['_trackEvent', 'tool_wangSign', 'href', '旺旺打标跳转来源电商工具'])
      },
      top20w () {
        window._hmt.push(['_trackEvent', 'tool_top20w', 'href', 'TOP20W词库跳转来源电商工具'])
      },
      diction () {
        window._hmt.push(['_trackEvent', 'tool_diction', 'href', '下拉框选词跳转来源电商工具'])
      },
      picture () {
        window._hmt.push(['_trackEvent', 'tool_picture', 'href', '主图/视频...下载跳转来源电商工具'])
      },
      weightPromote () {
        window._hmt.push(['_trackEvent', 'tool_weightPromote', 'href', '权重提升计划跳转来源电商工具'])
      },
      terminal () {
        window._hmt.push(['_trackEvent', 'tool_terminal', 'href', 'PC/无线...下载跳转来源电商工具'])
      },
      category () {
        window._hmt.push(['_trackEvent', 'tool_category', 'href', '类目查询跳转来源电商工具'])
      },
      firstScreent () {
        window._hmt.push(['_trackEvent', 'tool_firstScreent', 'href', '关键词卡首屏跳转来源电商工具'])
      }
    },
    // 运营服务
    operationServices: {
      operate () {
        window._hmt.push(['_trackEvent', 'operationServices_operate', 'href', '爆款运营服务跳转来源运营服务名称'])
      },
      shopDiagnosis () {
        window._hmt.push(['_trackEvent', 'operationServices_shopDiagnosis', 'href', '店铺诊断跳转来源运营服务名称'])
      }
    },
    // 侧导航-工具箱
    menuTool (name, title) {
      window._hmt.push(['_trackEvent', 'menuTool_' + name, 'href', title + '跳转来源侧导航'])
    },
    // 头部导航
    menuHead (name, title) {
      console.log(585, name, title)
      window._hmt.push(['_trackEvent', 'menuHead_' + name, 'href', title + '跳转来源头部导航'])
    },
    serviceMarket () {
      window._hmt.push(['_trackEvent', 'serviceMarket', 'href', '服务市场跳转来源首页'])
    },
    activationCode: {
      ranking () {
        window._hmt.push(['_trackEvent', 'activateCode_ranking', 'href', '激活码跳转来源查排名'])
      },
      orderDetection () {
        window._hmt.push(['_trackEvent', 'activateCode_orderDetection', 'href', '激活码跳转来源淘宝订单查询'])
      },
      wangSign () {
        window._hmt.push(['_trackEvent', 'activateCode_wangSign', 'href', '激活码跳转来源旺旺打标'])
      },
      top20w () {
        window._hmt.push(['_trackEvent', 'activateCode_top20w', 'href', '激活码跳转来源TOP20W词库'])
      },
      diction () {
        window._hmt.push(['_trackEvent', 'activateCode_diction', 'href', '激活码跳转来源下拉框选词'])
      },
      picture () {
        window._hmt.push(['_trackEvent', 'activateCode_picture', 'href', '激活码跳转来源主图/视频/SKU图下载'])
      },
      weightPromote () {
        window._hmt.push(['_trackEvent', 'activateCode_weightPromote', 'href', '激活码跳转来源权重提升计划'])
      },
      terminal () {
        window._hmt.push(['_trackEvent', 'activateCode_terminal', 'href', '激活码跳转来源PC/无线详情页下载'])
      },
      category () {
        window._hmt.push(['_trackEvent', 'activateCode_category', 'href', '激活码跳转来源类目查询'])
      },
      firstScreent () {
        window._hmt.push(['_trackEvent', 'activateCode_firstScreent', 'href', '激活码跳转来源关键词卡首屏'])
      },
      wangQuery () {
        window._hmt.push(['_trackEvent', 'activateCode_wangQuery', 'href', '激活码跳转来源查黑号'])
      },
      conversion () {
        window._hmt.push(['_trackEvent', 'activateCode_conversion', 'href', '激活码跳转来源指数转化'])
      },
      oldPrice () {
        window._hmt.push(['_trackEvent', 'activateCode_oldPrice', 'href', '激活码跳转来源历史价格查询'])
      },
      compPerspective () {
        window._hmt.push(['_trackEvent', 'activateCode_compPerspective', 'href', '激活码跳转来源竞品透视'])
      },
      generateTaoPassword () {
        window._hmt.push(['_trackEvent', 'activateCode_generateTaoPassword', 'href', '激活码跳转来源淘口令生成'])
      },
      adviserGetWord () {
        window._hmt.push(['_trackEvent', 'activateCode_adviserGetWord', 'href', '激活码跳转来源生意参谋取词'])
      },
      compFirstscreen () {
        window._hmt.push(['_trackEvent', 'activateCode_compFirstscreen', 'href', '激活码跳转来源蹭竞品卡首屏'])
      }
    },
    signModal: {
      ranking () {
        window._hmt.push(['_trackEvent', 'activateCode_ranking', 'href', '登录/注册弹框跳转来源查排名'])
      },
      orderDetection () {
        window._hmt.push(['_trackEvent', 'activateCode_orderDetection', 'href', '登录/注册弹框跳转来源淘宝订单查询'])
      },
      wangSign () {
        window._hmt.push(['_trackEvent', 'activateCode_wangSign', 'href', '登录/注册弹框跳转来源旺旺打标'])
      },
      top20w () {
        window._hmt.push(['_trackEvent', 'activateCode_top20w', 'href', '登录/注册弹框跳转来源TOP20W词库'])
      },
      diction () {
        window._hmt.push(['_trackEvent', 'activateCode_diction', 'href', '登录/注册弹框跳转来源下拉框选词'])
      },
      picture () {
        window._hmt.push(['_trackEvent', 'activateCode_picture', 'href', '登录/注册弹框跳转来源主图/视频/SKU图下载'])
      },
      weightPromote () {
        window._hmt.push(['_trackEvent', 'activateCode_weightPromote', 'href', '登录/注册弹框跳转来源权重提升计划'])
      },
      terminal () {
        window._hmt.push(['_trackEvent', 'activateCode_terminal', 'href', '登录/注册弹框跳转来源PC/无线详情页下载'])
      },
      category () {
        window._hmt.push(['_trackEvent', 'activateCode_category', 'href', '登录/注册弹框跳转来源类目查询'])
      },
      firstScreent () {
        window._hmt.push(['_trackEvent', 'activateCode_firstScreent', 'href', '登录/注册弹框跳转来源关键词卡首屏'])
      },
      wangQuery () {
        window._hmt.push(['_trackEvent', 'activateCode_wangQuery', 'href', '登录/注册弹框跳转来源查黑号'])
      },
      conversion () {
        window._hmt.push(['_trackEvent', 'activateCode_conversion', 'href', '登录/注册弹框跳转来源指数转化'])
      },
      oldPrice () {
        window._hmt.push(['_trackEvent', 'activateCode_oldPrice', 'href', '登录/注册弹框跳转来源历史价格查询'])
      },
      compPerspective () {
        window._hmt.push(['_trackEvent', 'activateCode_compPerspective', 'href', '登录/注册弹框跳转来源竞品透视'])
      },
      generateTaoPassword () {
        window._hmt.push(['_trackEvent', 'activateCode_generateTaoPassword', 'href', '登录/注册弹框跳转来源淘口令生成'])
      },
      adviserGetWord () {
        window._hmt.push(['_trackEvent', 'activateCode_adviserGetWord', 'href', '登录/注册弹框转来源生意参谋取词'])
      },
      compFirstscreen () {
        window._hmt.push(['_trackEvent', 'activateCode_compFirstscreen', 'href', '登录/注册弹框跳转来源蹭竞品卡首屏'])
      },
      flowIndex () {
        window._hmt.push(['_trackEvent', 'activateCode_flowIndex', 'href', '登录/注册弹框跳转来源流量首页'])
      },
      flowRecharge () {
        window._hmt.push(['_trackEvent', 'activateCode_flowRecharge', 'href', '登录/注册弹框跳转来源流量充值'])
      },
      taskQuery () {
        window._hmt.push(['_trackEvent', 'activateCode_taskQuery', 'href', '登录/注册弹框跳转来源任务查询'])
      },
      taskTemplate () {
        window._hmt.push(['_trackEvent', 'activateCode_taskTemplate', 'href', '登录/注册弹框跳转来源任务模板'])
      },
      personalCenter () {
        window._hmt.push(['_trackEvent', 'activateCode_personalCenter', 'href', '登录/注册弹框跳转来源个人中心'])
      }
    }
  }
}
export default Statistical
